<template>
  <div class="import-contacts">
    <h3>Step 1. Import Contacts</h3>
    <contact-c-s-v-importer v-model="contacts" />
    <template>
      <div class="splitter row">or</div>
    </template>
    <div class="row">
      <div class="add-contact-form" @keyup.enter="onAddContact">
        <h4>Add Contacts Manually</h4>
        <div class="form">
          <div class="col">
            <diyobo-input
              type="text"
              label="First Name"
              placeholder="First Name"
              required
              v-model="formValues.firstName"
              :val="formValues.firstName"
              :error="formErrors.firstName"
              @keyup="validateAt('firstName')"
            />
          </div>
          <div class="col">
            <diyobo-input
              type="text"
              label="Last Name"
              placeholder="Last Name"
              required
              v-model="formValues.lastName"
              :val="formValues.lastName"
              :error="formErrors.lastName"
              @keyup="validateAt('lastName')"
            />
          </div>
          <div class="col">
            <diyobo-input
              type="text"
              label="Email"
              placeholder="Email"
              required
              v-model="formValues.email"
              :val="formValues.email"
              :error="formErrors.email"
              @keyup="validateAt('email')"
            />
          </div>
          <div class="col">
            <diyobo-input
              type="text"
              label="Phone Number"
              placeholder="Phone Number"
              v-model="phoneOutput"
              :autocomplete="'tel'"
              :max="16"
              :numsOnly="true"
              :val="formValues.phone"
              :error="formErrors.phone"
              @keyup="validateAt('phone')"
            />
          </div>
          <diyobo-button type="primary" txt="Submit" @click="onAddContact" />
        </div>
      </div>
    </div>

    <template v-if="contacts.length > 0">
      <table-tote v-model="importTable" />
      <h3>Step 3. Add To A List</h3>
      <div class="import-type">
        <diyobo-button
          txt="Create List"
          small
          :type="importType === 'create' ? 'primary' : 'secondary'"
          @click="importType = 'create'"
        />
        <diyobo-button
          txt="Update List"
          small
          :type="importType === 'update' ? 'primary' : 'secondary'"
          @click="importType = 'update'"
        />
      </div>
      <div class="select-list row">
        <div v-if="importType === 'update'" class="col half">
          <diyobo-input
            type="dropdown"
            label="Select List"
            placeholder="Select a List"
            :options="listOptions"
            :val="selectedList"
            :bus="bus"
            @input="onSelectList"
          />
        </div>
        <div v-if="importType === 'create'" class="col half">
          <diyobo-input
            type="text"
            label="New List"
            placeholder="List Name"
            :val="listName"
            v-model="listName"
          />
        </div>
        <diyobo-button type="primary" txt="Submit" @click="onSubmit" />
      </div>
    </template>
  </div>
</template>

<script>
import DiyoboButton from "@/components/DiyoboButton.vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import TableTote from "@/components/TableTote.vue";
import { formatPhoneNumber } from "@/helpers/input-formats.js";
import * as yup from "yup";
import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import ContactCSVImporter from "./ContactCSVImporter";

const VALIDATION_SCHEMA = yup.object({
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  email: yup
    .string()
    .email("Invalid email provided.")
    .required("Email is required."),
  phone: yup.string()
});

library.add(faTrash);

export default {
  name: "import-contacts",
  components: {
    ContactCSVImporter,
    DiyoboButton,
    DiyoboInput,
    TableTote
  },
  props: {
    lists: Array,
    event: String
  },
  data() {
    return {
      contacts: [],
      listName: "",
      selectedList: null,
      bus: new Vue(),
      importType: "create",
      importTable: null,
      formValues: {
        firstName: "",
        lastName: "",
        email: "",
        phone: ""
      },
      formErrors: {
        firstName: "",
        lastName: "",
        email: "",
        phone: ""
      }
    };
  },
  computed: {
    phoneOutput: {
      get() {
        return this.formValues.phone;
      },
      set(value) {
        this.formValues.phone = formatPhoneNumber(value);
      }
    },
    listOptions() {
      return this.lists
        ? this.lists.map(l => ({ label: l.name, value: l._id }))
        : [];
    }
  },
  watch: {
    contacts(value) {
      this.importTable.data = value;
    }
  },
  methods: {
    validateAt(field, context) {
      VALIDATION_SCHEMA.validateAt(field, this.formValues, { context })
        .then(() => {
          this.formErrors[field] = "";
        })
        .catch(err => {
          this.formErrors[field] = err.message;
        });
    },
    async onAddContact() {
      try {
        await VALIDATION_SCHEMA.validate(this.formValues, {
          abortEarly: false
        });
      } catch (error) {
        error.inner.forEach(err => {
          this.formErrors[err.path] = err.message;
        });

        return;
      }

      const index = this.contacts.findIndex(
        c => c.email.toLowerCase() === this.formValues.email.toLowerCase()
      );

      const contact = {
        firstName: this.formValues.firstName,
        lastName: this.formValues.lastName,
        email: this.formValues.email,
        phone: this.formValues.phone,
        delete: true
      };

      if (index > -1) {
        this.contacts.splice(index, 1, contact);
      } else {
        this.contacts.unshift(contact);
      }

      this.formValues.firstName = "";
      this.formValues.lastName = "";
      this.formValues.email = "";
      this.formValues.phone = "";
    },
    onSelectList(value) {
      this.selectedList = value;
    },
    onSubmit() {
      this.$loader.start();

      let data = {
        event: this.event,
        contacts: this.contacts
      };

      if (this.importType === "update") {
        data.listID = this.selectedList;
      } else {
        data.name = this.listName;
      }

      this.$axios
        .post("/emails/lists/import", data)
        .then(response => {
          const data = response.data;

          this.$emit("submit", data.list);

          const report = data.report;

          this.$bus.$emit("alert", {
            msg: `You tried to add ${report.imported} emails, ${report.added} were added, ${report.duplicates} were duplicates and ${report.errored} were invalid emails.`
          });

          this.contacts = [];
          this.selectedList = null;
          this.listName = "";
        })
        .finally(() => {
          this.$loader.stop();
        });
    }
  },
  created() {
    this.importTable = {
      title: "Step 2. Review Contacts To Import",
      columns: [
        { title: "First Name", field: "firstName", width: "25%", input: true },
        { title: "Last Name", field: "lastName", width: "25%", input: true },
        { title: "Email", field: "email", width: "25%", input: true },
        {
          title: "Phone Number",
          field: "phone",
          width: "25%",
          input: true,
          phone: true
        },
        { title: "Delete", field: "delete", width: "7%", icon: true }
      ],
      data: [],
      searchable: ["first_name", "last_name", "email"],
      icons: {
        delete: () => "trash"
      },
      callbacks: {
        delete: contact => {
          const index = this.contacts.indexOf(contact);

          if (index > -1) {
            this.contacts.splice(index, 1);
          }
        }
      }
    };
  }
};
</script>

<style lang="less" scoped>
.import-contacts {
  .select-list {
    button {
      margin: auto auto auto 10px;
    }
  }

  .splitter {
    display: flex;
    justify-content: center !important;
    position: relative;
    font-weight: bold;
    padding: 10px;

    &::before {
      content: " ";
      display: block;
      position: absolute;
      top: 50%;
      right: 58%;
      width: 25%;
      border-bottom: 2px solid @colors[primary-green];
    }

    &::after {
      content: " ";
      display: block;
      position: absolute;
      top: 50%;
      left: 58%;
      width: 25%;
      border-bottom: 2px solid @colors[primary-green];
    }
  }

  .add-contact-form {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 16px;
    border: 1px solid var(--dashboard-border);
    border-radius: 5px;
    box-shadow: var(--table-shadow);

    h4 {
      margin: 0 0 8px 0;
    }

    button {
      margin: auto 0 2px 0;
    }

    .form {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(4, 1fr) auto;
      gap: 16px;

      .input-wrapper {
        margin: 0 16px 0 0;
      }

      @media screen and (max-width: 850px) {
        grid-template-columns: none;
      }
    }
  }

  .import-type {
    display: flex;
    margin-bottom: 16px;

    button {
      margin-right: 16px;
    }
  }
}
</style>
