<template>
  <modal :title="customTitle" :active="active" :closable="false">
    <p class="b1 modal-message">
      {{ customMessage }}
    </p>
    <p class="b1 modal-message">
      {{ notice }}
    </p>
    <div class="loader-wrapper">
      <div class="loader"></div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "spinner-loading-modal",
  components: {
    Modal
  },
  data() {
    return {
      active: false
    };
  },
  props: {
    customTitle: { default: "Loading", type: String },
    customMessage: { default: "", type: String },
    notice: { default: "", type: String }
  },
  methods: {
    open() {
      this.active = true;
      document.body.style.overflow = "hidden";
    },
    close() {
      this.active = false;
      document.body.style.overflow = "initial";
    }
  }
};
</script>

<style lang="less" scoped>
.modal {
  .modal-inner {
    .modal-dialog {
      .modal-body {
        .modal-message {
          padding-bottom: 0.5em;
          text-align: center;
          font-weight: 600;
        }

        .loader-wrapper {
          display: flex;
          justify-content: center;

          .loader {
            border: 8px solid #f3f3f3;
            border-top: 8px solid #4eb95e;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            animation: spin 1s ease-in-out infinite;
          }
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}
</style>