<template>
  <div class="instant-email">
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <div class="content-path">
          <breadcrumbs :crumbs="breadcrumbs" />
          <div class="page-tabs">
            <tab-tote
              :tabs="navTabs"
              :value="navTabSelected"
              @click="onClickNavTab"
            />
          </div>
        </div>
        <hr class="divider" />
        <router-view :url="url" @set-title="title = arguments[0]" />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import TabTote from "@/components/TabTote.vue";

export default {
  name: "emails",
  components: {
    Breadcrumbs,
    TabTote
  },
  props: {
    url: String
  },
  data() {
    return {
      breadcrumbs: [
        ["Dashboard", "/dashboard"],
        ["Emails", null]
      ],
      navTabs: [
        "Instant Email",
        "Automation",
        "Custom Emails",
        "Import Lists",
        "Errors"
      ],
      title: ""
    };
  },
  computed: {
    navTabSelected() {
      const path = this.$route.path;

      if (path.endsWith("instant-email")) {
        return 0;
      } else if (path.endsWith("automation")) {
        return 1;
      } else if (path.endsWith("custom-emails")) {
        return 2;
      } else if (path.endsWith("lists")) {
        return 3;
      } else if (path.endsWith("errors")) {
        return 4;
      }

      return 0;
    }
  },
  methods: {
    onClickNavTab(index) {
      const path = this.$route.path;

      if (index === 0 && !path.endsWith("instant-email")) {
        this.$router.push(`/emails/${this.url}/instant-email`);
      } else if (index === 1 && !path.endsWith("automation")) {
        this.$router.push(`/emails/${this.url}/automation`);
      } else if (index === 2 && !path.endsWith("custom-emails")) {
        this.$router.push(`/emails/${this.url}/custom-emails`);
      } else if (index === 3 && !path.endsWith("lists")) {
        this.$router.push(`/emails/${this.url}/lists`);
      } else if (index === 4 && !path.endsWith("errors")) {
        this.$router.push(`/emails/${this.url}/errors`);
      }
    }
  },
  created() {
    this.$store.commit("setTitle", "Email");
    this.$store.commit(
      "setHelpVideoSrc",
      "https://youtube.com/embed/X6pOpf9Nva4"
    );
  }
};
</script>

<style lang="less" scoped>
#Content {
  transition: height 0.5s;

  hr {
    max-width: 1100px;
    border: rgba(91, 91, 91, 0.5) solid 0.5px;
    margin-bottom: 25px;
  }

  &::v-deep .content-inner {
    .content-path {
      padding-bottom: 0;

      hr {
        margin: 25px 0;
      }

      .buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        @media screen and (max-width: 555px) {
          justify-content: center;
        }
      }
    }
    //end creation-path

    .page-tabs {
      padding: 20px 0;
    }

    h2 {
      text-align: center;
    }
  }
  // end form-inner
}
</style>
