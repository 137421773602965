<template>
  <div class="csv-importer">
    <input
      type="file"
      name="csv"
      id="csv"
      accept="text/csv"
      ref="input"
      @input="onUploadCSV"
    />
    <div class="left">
      <h4>Import via CSV</h4>
      <span>
        Column Headers:
        <span v-for="(column, index) in columns" class="column" :key="index">
          {{ column.name }}
        </span>
      </span>
      <span v-if="error" class="error">
        {{ error }}
      </span>
    </div>
    <div class="right">
      <div v-if="fileName" class="file-info">
        <span>{{ fileName }}</span>
        <span>({{ importCount }} items)</span>
      </div>
      <diyobo-button type="primary" txt="Import" @click="onClickImport" />
    </div>
  </div>
</template>

<script>
import Papa from "papaparse";
import DiyoboButton from "@/components/DiyoboButton.vue";

export default {
  name: "ContactCSVImporter",
  components: {
    DiyoboButton
  },
  props: {
    value: Array
  },
  data() {
    return {
      importCount: 0,
      fileName: "",
      error: null,
      columns: [
        { name: "first_name", required: true },
        { name: "last_name", required: true },
        { name: "email", required: true },
        { name: "phone" }
      ]
    };
  },
  methods: {
    onClickImport() {
      this.$refs.input.click();
    },
    onUploadCSV(event) {
      const csv = event.target.files[0];

      Papa.parse(csv, {
        header: true,
        complete: result => {
          const contacts = [];

          const missingColumns = this.columns.filter(
            c => c.required && !result.meta.fields.includes(c.name)
          );

          if (missingColumns.length > 0) {
            this.error =
              "Missing required columns: " +
              missingColumns.map(c => c.name).join(", ");
            return;
          }

          if (contacts.length > 50000) {
            this.error =
              "The maximum amount of contacts that can be imported is 50000";
            return;
          }

          this.error = null;

          for (const item of result.data) {
            contacts.push({
              firstName: item.first_name,
              lastName: item.last_name,
              email: item.email,
              phone: item.phone,
              delete: true
            });
          }

          this.importCount = contacts.length;
          this.fileName = csv.name;

          this.$emit("input", [...contacts, ...this.value]);
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.csv-importer {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid var(--dashboard-border);
  border-radius: 5px;
  box-shadow: var(--table-shadow);

  #csv {
    display: none;
  }

  .left {
    display: flex;
    flex-direction: column;

    h4 {
      margin: 0 0 8px 0;
    }

    .column {
      padding: 2px 4px;
      margin: 4px;
      background: var(--event-border);
      border-radius: 4px;
    }

    .error {
      color: var(--error-red);
    }
  }

  .right {
    display: flex;
    align-items: center;

    .file-info {
      span {
        margin-right: 8px;
      }
    }
  }
}
</style>
