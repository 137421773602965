<template>
  <div class="tier-selection">
    <div class="tier">
      <FormulateInput
        type="checkbox"
        :label="tier.display"
        v-model="tier.selected"
        @input="onCheckTier(tier, $event)"
      />
    </div>
    <div v-if="false" class="dates">
      <div v-for="date in tier.venueAccessDates" class="date" :key="date.id">
        <div class="left">
          <FormulateInput
            type="checkbox"
            :label="date.display"
            v-model="selection[date.id]"
            @input="onCheckDate(date, $event)"
          />
        </div>
        <div class="right">
          <div v-for="time in date.times" class="time" :key="time.id">
            <FormulateInput
              type="checkbox"
              :label="time.display"
              v-model="selection[time.id]"
              @input="onCheckTime(time, $event)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "TierSelection",
  props: {
    tier: Object,
    event: String,
    selections: Object
  },
  computed: {
    selection() {
      const blah = this.selections[this.event];

      if (!blah) {
        return {};
      }

      return Object.values(blah).reduce((a, b) => {
        a[b] = true;
        return a;
      });
    }
  },
  methods: {
    onCheckTier(tier, value) {
      this.$emit("selected", tier, value);
    },
    onCheckDate(date, value) {
      this.$emit("selected", "dates", date, this.event, value);
    },
    onCheckTime(time, value) {
      this.$emit("selected", "times", time, this.event, value);
    }
  }
};
</script>

<style lang="less" scoped>
.tier-selection {
  display: flex;
  padding: 10px;
  border: 1px solid #333b3f;
  border-radius: 5px;
  margin-bottom: 5px;

  .tier {
    width: 50%;

    &::v-deep .formulate-input {
      margin-bottom: 0;
    }
  }

  .dates {
    display: flex;
    flex-direction: column;
    width: 66%;

    .date {
      display: flex;

      .left,
      .right {
        width: 50%;
      }

      .times {
        .time {
          &::v-deep .formulate-input {
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}
</style>
