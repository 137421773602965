<template>
  <div class="instant-email">
    <select-co-events-modal
      ref="selectCoEventsModal"
      v-model="coEvents"
      :selections="selections"
      :co-events-selected="coEventsSelected"
      :co-event-tiers-selected="coEventTiersSelected"
      :co-event-recipients="coEventRecipients"
      @update="onCoEventTiersSelected"
      @selected="onCoEventTiersSelected"
    />
    <SelectTiersModal
      v-if="event"
      ref="selectTiersModal"
      :event="event.id"
      v-model="tiers"
      :selections="selections"
      @selected="handleSelected"
    />
    <SpinnerLoadingModal
      ref="loadingModal"
      customTitle="Email Sending"
      customMessage="Please wait while we send your email."
    />
    <h2>Instant Email</h2>
    <div v-if="false" class="usage">
      <div class="counter">
        <span class="count">{{ emailsSent }} / 3</span>
        <span class="free-emails">free emails sent</span>
      </div>
      <span class="additional">
        Additional emails will be billed at $4/each.
      </span>
    </div>
    <section class="row">
      <div class="col quarter">
        <section class="who-to-mail">
          <h2>Who To Email</h2>
          <div class="error" v-if="formErrors.tiers">
            You must select a tier from this event.
          </div>
          <div v-if="tiers === null" class="event">
            <loader />
          </div>
          <div v-else class="event">
            <h4>This Event</h4>
            <template v-if="isGeneralTierOnly">
              <diyobo-checkbox
                v-for="(tier, index) in tiers"
                class="ticket-tier"
                :key="index"
                :label="tier.display"
                :checked="tier.selected"
                :disabled="tier.disabled"
                @input="handleSelected(tier, arguments[0])"
              />
              <diyobo-button
                class="select-button"
                type="primary"
                small
                :txt="allTiersSelected ? 'Deselect All' : 'Select All'"
                @click="onSelectAllTiers"
              />
            </template>
            <diyobo-button
              v-else
              class="select-button"
              type="primary"
              small
              txt="Select Tiers"
              @click="onSelectTiers"
            />
            <h4>Guest List</h4>
            <template v-if="guestlist_submissions.count > 0">
              <FormulateInput
                v-model="guestlist_submissions.selected"
                type="checkbox"
                name="guestlist_submissions"
                :label="`Submissions (${guestlist_submissions.count})`"
                autocomplete="false"
              />
            </template>
            <template v-if="guestlist_sections.count > 0">
              <FormulateInput
                v-model="guestlist_sections.selected"
                type="checkbox"
                name="guestlist_sections"
                :label="`Sections (${guestlist_sections.count})`"
                autocomplete="false"
              />
            </template>
            <template v-if="guestlist_bottles.count > 0">
              <FormulateInput
                v-model="guestlist_bottles.selected"
                type="checkbox"
                name="guestlist_bottles"
                :label="`Bottles (${guestlist_bottles.count})`"
                autocomplete="false"
              />
            </template>
            <template v-if="guestlist_foods.count > 0">
              <FormulateInput
                v-model="guestlist_foods.selected"
                type="checkbox"
                name="guestlist_foods"
                :label="`Food (${guestlist_foods.count})`"
                autocomplete="false"
              />
            </template>
            <template v-if="guestlist_packages.count > 0">
              <FormulateInput
                v-model="guestlist_packages.selected"
                type="checkbox"
                name="guestlist_packages"
                :label="`Packages (${guestlist_packages.count})`"
                autocomplete="false"
              />
            </template>
          </div>

          <div class="event" v-if="presaleUsers.counts > 0">
            <h4>Presale Users</h4>

            <template>
              <diyobo-checkbox
                class="ticket-tier"
                :label="`Presale Users (${presaleUsers.counts})`"
                :checked="presaleUsers.selected"
                @input="presaleUsersSelected"
              />
            </template>
          </div>
          <div class="co-events">
            <div v-if="coEvents === null" class="event">
              <loader />
            </div>
            <div v-else class="event">
              <h4>Co-Events</h4>
              <p>
                Note that this email counts towards the free email limit of each
                selected co-event.
              </p>
              <div>Co-Events Selected: {{ coEventsSelected }}</div>
              <div>Tiers Selected: {{ coEventTiersSelected }}</div>
              <div>Potential Recipients: {{ coEventRecipients }}</div>
              <diyobo-button
                class="select-button"
                type="primary"
                small
                txt="Select Tiers"
                @click="onSelectCoEvents"
              />
            </div>
          </div>
          <div v-if="lists === null" class="event">
            <loader />
          </div>
          <div v-else class="event">
            <h4>Imported Lists</h4>
            <diyobo-checkbox
              v-for="(list, index) in lists"
              class="ticket-tier"
              :key="index"
              :label="list.display"
              :checked="list.selected"
              :disabled="list.disabled"
              @input="handleCheckbox(list, arguments[0])"
            />
            <diyobo-button
              class="select-button"
              type="primary"
              small
              :txt="allListsSelected ? 'Deselect All' : 'Select All'"
              @click="onSelectAllLists"
            />
          </div>
        </section>
      </div>
      <div class="col quarterx3" id="compose-mail-section">
        <h2>Compose Your Email</h2>
        <p>
          The denoted user count to the left is based on unique emails, not the
          total tickets sold. So, if a user buys 12 tickets under 1 email, they
          will be represented as 1 in the buyer list.
        </p>
        <div class="row">
          <FormulateInput
            type="select"
            label="Previous Email"
            placeholder="Select a Previous Email"
            help="You can select one of your previous emails from this dropdown to populate its content below."
            validation="required"
            autocomplete="false"
            :options="previousEmailsForDropdown"
            @input="onSelectPreviousEmail"
          />

          <!-- <diyobo-input
            type="dropdown"
            
           
            sublabel="You can select one of your previous emails from this dropdown to populate its content below."
            :options="previousEmailsForDropdown"
            :val="previousEmail"
            :bus="bus"
            
          /> -->
        </div>
        <FormulateForm
          name="instant-email-group"
          :errors="formErrors"
          v-model="formValues"
        >
          <div class="row">
            <div class="input-wrapper col full template-name">
              <FormulateInput
                type="text"
                label="Template Name"
                placeholder="My Template"
                help="Save the content below as a template for use in future emails."
                validation="required"
                autocomplete="false"
                name="templateName"
                v-model="formValues.templateName"
                :val="formValues.templateName"
              />
              <!-- <diyobo-input
              type="text"
              label="Template Name"
              placeholder="My Template"
              sublabel="Save the content below as a template for use in future emails."
              v-model="formValues.templateName"
              :val="formValues.templateName"
              :error="formErrors.templateName"
            /> -->
              <diyobo-button type="primary" txt="Save" @click="saveTemplate" />
            </div>
          </div>
          <div class="row">
            <div class="input-wrapper col half">
              <FormulateInput
                type="text"
                label="From Name"
                placeholder="Display Name in Buyer's Inbox"
                validation="required"
                name="name"
                v-model="formValues.name"
                :val="formValues.name"
                @keyup="validateAt('name')"
              />
            </div>
            <div class="input-wrapper col half">
              <FormulateInput
                type="text"
                label="From Email"
                placeholder="Display Email in Buyer's Inbox"
                validation="required"
                name="email"
                v-model="formValues.email"
                :val="formValues.email"
                :disabled="$store.state.user.activeBrand.subdomain == 'ownyourworth'"
                @keyup="validateAt('email')"
              />
            </div>
          </div>
          <FormulateInput
            type="text"
            label="Subject Line"
            placeholder="Grab Attention!"
            validation="required"
            name="subject"
            v-model="formValues.subject"
            :val="formValues.subject"
            @keyup="validateAt('subject')"
          />
        </FormulateForm>

        <div id="wysiwyg-wrapper">
          <FormulateInput
            type="wysiwyg"
            label="Email Content"
            name="content"
            placeholder="Write your message here..."
            validation="required"
            :inputHeight="250"
            v-model="formValues.content"
            :val="formValues.content"
            @keyup="validateAt('content')"
          />
        </div>

        <send-mail-image-uploader
          ref="attachments"
          v-on:input="addAttachment"
          v-on:error="uploadError"
        />
      </div>
      <div class="col full button-wrapper">
        <diyobo-button
          :disabled="sending"
          txt="Test"
          type="primary-dark"
          @click="send('test')"
        />
        <diyobo-button
          :disabled="sending"
          txt="Send"
          type="primary"
          @click="send('send')"
        />
      </div>
    </section>
  </div>
</template>

<script>
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboCheckbox from "@/components/DiyoboCheckbox.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import wysiwyg from "../../components/WYSIWYG.vue";
import SendMailImageUploader from "../../components/SendMailImageUploader.vue";
import ModalContent from "@/helpers/modals";
import * as yup from "yup";
import SelectCoEventsModal from "../../components/modals/SelectCoEventsModal.vue";
import Loader from "@/components/Loader.vue";
import SelectTiersModal from "../../components/emails/SelectTiersModal.vue";
import Vue from "vue";
import dayjs from "dayjs";
import SpinnerLoadingModal from "@/components/modals/SpinnerLoadingModal.vue";

const VALIDATION_SCHEMA = yup.object({
  name: yup.string().required("From Name is required."),
  email: yup
    .string()
    .email()
    .required("From Email is required."),
  subject: yup.string().required("Subject Line is required."),
  content: yup.string().required("Content is required."),
  tiers: yup
    .array()
    .test(
      "selected-tiers",
      (value, { options }) =>
        options.context.test ||
        true ||
        options.context.tiers.some(t => t.selected)
    )
});

const TEMPLATE_VALIDATION_SCHEMA = yup.object({
  templateName: yup.string().required("Template Name is required."),
  name: yup.string().required("From Name is required."),
  email: yup
    .string()
    .email()
    .required("From Email is required."),
  subject: yup.string().required("Subject Line is required."),
  content: yup.string().required("Content is required.")
});

export default {
  name: "instant-email",
  components: {
    DiyoboInput,
    DiyoboButton,
    DiyoboCheckbox,
    wysiwyg,
    SendMailImageUploader,
    SelectCoEventsModal,
    Loader,
    SelectTiersModal,
    SpinnerLoadingModal
  },
  props: {
    url: String
  },
  head() {
    return {
      title: "Instant Email"
    };
  },
  data() {
    return {
      bus: new Vue(),
      attachments: [],
      event: null,
      tiers: null,
      coEvents: null,
      lists: null,
      guestlist_submissions: {
        count: 0,
        selected: false
      },
      guestlist_sections: {
        count: 0,
        selected: false
      },
      guestlist_bottles: {
        count: 0,
        selected: false
      },
      guestlist_foods: {
        count: 0,
        selected: false
      },
      guestlist_packages: {
        count: 0,
        selected: false
      },

      presaleUsers: {
        counts: 0,
        selected: false
      },
      emailsSent: 0,
      total: 0,
      selected: [],
      templates: [],
      previousEmail: null,
      sending: false,
      isSent: false,
      testIsSent: false,
      selections: {},
      formValues: {
        templateName: "",
        name: "",
        email: "",
        subject: "",
        content: ""
      },
      formErrors: {
        templateName: "",
        name: "",
        email: "",
        subject: "",
        content: "",
        tiers: ""
      }
    };
  },
  computed: {
    allTiersSelected() {
      return this.tiers.every(t => t.disabled || t.selected);
    },
    coEventsSelected() {
      if (!this.coEvents) {
        return 0;
      }

      return this.coEvents.filter(e => e.tiers.some(e => e.selected)).length;
    },
    coEventTiersSelected() {
      if (!this.coEvents) {
        return 0;
      }

      const tiers = this.coEvents
        .map(e => e.tiers.filter(e => e.selected).length)
        .reduce((a, b) => a + b, 0);

      return tiers;
    },
    coEventRecipients() {
      if (!this.coEvents) {
        return 0;
      }

      return this.coEvents.reduce(
        (a, b) =>
          a + b.tiers.filter(t => t.selected).reduce((a, b) => a + b.count, 0),
        0
      );
    },
    filteredSelections() {
      const selections = Object.entries(this.selections).filter(e =>
        Object.values(e).some(a => a.length > 0)
      );

      return Object.fromEntries(selections);
    },
    allListsSelected() {
      return this.lists.every(l => l.selected);
    },
    previousEmailsForDropdown() {
      return this.templates.map(t => ({
        label: t.template_name,
        value: t._id
      }));
    },
    isGeneralTierOnly() {
      return this.event.tiers.every(t => t.type === "General Tier");
    }
  },
  watch: {
    selections: {
      handler(value) {
        console.log(value);
      },
      deep: true
    }
  },
  methods: {
    validateAt(field, context, type) {
      if (type === "template") {
        TEMPLATE_VALIDATION_SCHEMA.validateAt(field, this.formValues, {
          context
        })
          .then(() => {
            this.formErrors[field] = "";
          })
          .catch(err => {
            this.formErrors[field] = err.message;
          });
      } else {
        VALIDATION_SCHEMA.validateAt(field, this.formValues, { context })
          .then(() => {
            this.formErrors[field] = "";
          })
          .catch(err => {
            this.formErrors[field] = err.message;
          });
      }
    },
    async validate(test) {
      if (this.sending) return false;

      try {
        await VALIDATION_SCHEMA.validate(this.formValues, {
          abortEarly: false,
          context: {
            test,
            tiers: this.tiers
          }
        });

        this.formErrors.tiers = "";

        return true;
      } catch (error) {
        error.inner.forEach(err => {
          this.formErrors[err.path] = err.message;
        });

        this.$el.scrollIntoView({ behavior: "smooth" });

        return false;
      }
    },
    addAttachment(index, attachment) {
      this.attachments[index] = attachment;
    },
    uploadError(error) {
      this.$parent.$refs.alert.open({ title: "Error", msg: error });
    },
    handleCheckbox(item, value) {
      this.$set(item, "selected", value);
    },
    onSelectCoEvents() {
      this.$refs.selectCoEventsModal.open();
    },
    onSelectTiers() {
      this.$refs.selectTiersModal.open();
    },
    onSelectAllTiers() {
      const selected = !this.allTiersSelected;

      this.tiers
        .filter(t => !t.disabled)
        .forEach(tier => {
          this.$set(tier, "selected", selected);
        });
    },
    onSelectAllLists() {
      const selected = !this.allListsSelected;

      this.lists
        .filter(l => !l.disabled)
        .forEach(list => {
          this.$set(list, "selected", selected);
        });
    },
    onCoEventTiersSelected(tier, value) {
      this.$set(tier, "selected", value);
    },
    handleSelected(item, value) {
      this.$set(item, "selected", value);
    },
    presaleUsersSelected(value) {
      this.$set(this.presaleUsers, "selected", value);
    },
    onSelectPreviousEmail(value) {
      const template = this.templates.find(e => e._id === value);

      this.previousEmail = value;

      this.formValues.templateName = template.template_name;
      this.formValues.name = template.name;
      this.formValues.email = template.email;
      this.formValues.subject = template.subject;
      this.formValues.content = template.content;

      this.validateAt("name", "", "template");
      this.validateAt("templateName", "", "template");
      this.validateAt("email", "", "template");
      this.validateAt("subject", "", "template");
      this.validateAt("content", "", "template");
    },
    async saveTemplate() {
      // lazybones detected
      // this.formErrors.templateName = "";
      try {
        await TEMPLATE_VALIDATION_SCHEMA.validate(this.formValues, {
          abortEarly: false
        });
      } catch (error) {
        console.log(error);
        error.inner.forEach(err => {
          this.formErrors[err.path] = err.message;
        });

        this.$el.scrollIntoView({ behavior: "smooth" });

        return false;
      }

      this.$refs.loadingModal.open();

      this.$axios
        .post("/emails/save-template", {
          event: this.url,
          templateName: this.formValues.templateName,
          type: "INSTANT_EMAIL",
          name: this.formValues.name,
          email: this.formValues.email,
          subject: this.formValues.subject,
          content: this.formValues.content
        })
        .then(response => {
          const data = response.data;
          const template = data.template;
          const index = this.templates.findIndex(t => t.id === template.id);

          if (index > -1) {
            this.templates.splice(index, 1, template);
          } else {
            this.templates.unshift(template);
          }

          this.$bus.$emit("alert", {
            msg: "Your email template has been saved!"
          });
        })
        .finally(() => {
          this.$refs.loadingModal.close();
        });
    },
    async send(type) {
      const valid = await this.validate(type === "test");

      const coEvents = {};

      this.coEvents.forEach(event => {
        coEvents[event._id] = event.tiers
          .filter(t => t.selected)
          .map(t => t.id);
      });

      if (valid) {
        this.sending = true;
        this.$refs.loadingModal.open();

        // this.$loader.start();

        const coEvents = {};

        this.coEvents.forEach(event => {
          coEvents[event._id] = event.tiers
            .filter(t => t.selected)
            .map(t => t.id);
        });

        // send as FormData to properly handle image uploads
        const data = new FormData();

        // replace all \n to <br>
        let newval = this.formValues.content.replace(/\r\n|\r|\n/g, "<br>");
        newval = newval.replace(/white-space:(?:|\s)pre;/g, "");

        let appendData = {
          event: this.url,
          templateName: this.formValues.templateName,
          name: this.formValues.name,
          email: this.formValues.email,
          subject: this.formValues.subject,
          content: newval,
          lists: [
            ...this.tiers.filter(t => t.selected).map(t => t.listID),
            ...this.coEvents
              .map(e => e.tiers.filter(t => t.selected).map(t => t.listID))
              .flatMap(a => a),
            ...this.lists.filter(l => l.selected).map(l => l._id)
          ]
        };

        if (this.presaleUsers.selected === true) {
          appendData.presaleUsers = true;
        }

        if (this.guestlist_submissions.selected) {
          appendData.guestlist_submissions = true;
        }
        if (this.guestlist_sections.selected) {
          appendData.guestlist_sections = true;
        }
        if (this.guestlist_bottles.selected) {
          appendData.guestlist_bottles = true;
        }
        if (this.guestlist_foods.selected) {
          appendData.guestlist_foods = true;
        }

        if (this.guestlist_packages.selected) {
          appendData.guestlist_packages = true;
        }

        data.append("data", JSON.stringify(appendData));

        for (let i = 0; i < this.attachments.length; i++) {
          data.append(`attachment-${i}`, this.attachments[i]);
        }

        // Get Whitelabel Id for this account
        let user = this.$store.state.user._id;
        let whiteLabelId = "";

        try {
          let whiteLabelAcc = await this.$axios.post(
            "/white-label/get-white-label-by-user-id",
            { userId: user }
          );
          whiteLabelId = await whiteLabelAcc.data.whiteLabel._id;
        } catch (error) {
          console.log(error);
        }

        this.$axios
          .post(`/emails/instant-email/${type}`, data, {
            headers: {
              "X-Whitelabel-Id": whiteLabelId
            }
          })
          .then(response => {
            let data = response.data;
            if (!data.error) {
              if (type === "test") {
                const content = ModalContent.instantEmailTestSent;
                this.$store.state.bus.$emit("alert", {
                  title: content.title,
                  msg: content.msg.replace("{email}", this.formValues.email),
                  confirm: content.confirm
                });
              }

              if (type === "send") {
                if (data.blacklisted) {
                  const content = ModalContent.instantEmailSentBlacklisted;
                  this.$store.state.bus.$emit("alert", {
                    title: content.title,
                    msg: content.msg.replace("{blacklisted}", data.blacklisted),
                    confirm: content.confirm
                  });
                } else {
                  this.$store.state.bus.$emit("alert", {
                    msg: ModalContent.instantEmailSent.msg.replace(
                      "{recipients}",
                      data.recipients
                    )
                  });
                }

                this.emailsSent++;

                Object.keys(this.selections).forEach(k => {
                  this.initSelections(k);
                });

                this.tiers
                  .filter(t => t.selected)
                  .forEach(t => {
                    t.selected = false;
                    // TODO add back limits
                    // t.disabled = true;
                  });

                this.coEvents.forEach(e => {
                  e.emails_sent++;
                  e.tiers
                    .filter(t => t.selected)
                    .forEach(t => {
                      t.selected = false;
                      // TODO add back limits
                      // t.disabled = true;
                    });
                });

                this.formValues.templateName = "";
                this.formValues.name = "";
                this.formValues.email = "";
                this.formValues.subject = "";
                this.formValues.content = "";

                this.attachments = [];
                this.previousEmail = null;

                this.$refs.attachments.reset();
                this.$formulate.reset("instant-email-group");

                // Reset checkboxes
                this.presaleUsers.selected = false;
                this.guestlist_submissions.selected = false;
                this.guestlist_sections.selected = false;
                this.guestlist_bottles.selected = false;
                this.guestlist_foods.selected = false;
                this.guestlist_packages.selected = false;
              }
            } else {
              this.$store.state.bus.$emit("alert", {
                title: "Error",
                msg: data.error
              });
              this.$refs.loadingModal.close();
            }
          })
          .finally(() => {
            this.sending = false;
            this.$refs.loadingModal.close();
          });
      }
    },
    formatDate(date) {
      return dayjs(+date.start).format("MMM DD, YYYY");
    },
    formatTime(time) {
      return dayjs(+time.start).format("h:mm A");
    }
  },
  created() {
    this.$axios
      .post("/emails/instant-email", {
        event: this.url
      })
      .then(response => {
        const data = response.data;
        this.$emit("set-title", data.event.name);

        this.event = data.event;

        data.event.emails_sent = data.event.emails_sent || 0;
        data.event.tiers.forEach(t => {
          const orders = data.tiers.find(o => o.tier_id === t.id);
          t.count = orders ? orders.contacts : 0;
          t.display = `${t.name} (${t.count})`;
          t.listID = orders ? orders._id : null;

          if (!orders) {
            t.disabled = true;
          }
          // TODO add back limits
          // t.disabled = t.last_emailed && +t.last_emailed > yesterday;

          if (false && t.venueAccessDates) {
            t.venueAccessDates.forEach(d => {
              const orders = data.counts[d.id];

              d.count = orders ? orders : 0;
              d.display = `${this.formatDate(d)} (${d.count})`;

              if (d.times) {
                d.times.forEach(dt => {
                  const orders = data.counts[dt.id];

                  dt.count = orders ? orders : 0;
                  dt.display = `${this.formatTime(dt)} (${dt.count})`;
                });
              }
            });
          }
        });

        data.events.forEach(e => {
          e.emails_sent = e.emails_sent || 0;

          e.tiers.forEach(t => {
            const orders = data.tiers.find(o => o.tier_id === t.id);

            t.count = orders ? orders.contacts : 0;
            t.display = `${t.name} (${t.count})`;
            t.listID = orders ? orders._id : null;
            // TODO add back limits
            // t.disabled = t.last_emailed && +t.last_emailed > yesterday;

            if (!orders) {
              t.disabled = true;
            }

            if (false && t.venueAccessDates) {
              t.venueAccessDates.forEach(d => {
                const orders = data.tiers.find(t => t.tierID === t.id);

                d.count = orders ? orders.contacts : 0;
                d.display = `${this.formatDate(d)} (${d.count})`;

                if (d.times) {
                  d.times.forEach(dt => {
                    const orders = data.tiers.find(t => t.tierID === t.id);

                    dt.count = orders ? orders.contacts : 0;
                    dt.display = `${this.formatTime(dt)} (${dt.count})`;
                  });
                }
              });
            }
          });

          e.total = e.tiers.map(t => t.count).reduce((a, b) => a + b, 0);
        });

        data.lists.forEach(l => {
          l.display = `${l.name} (${l.contacts})`;
        });

        this.tiers = data.event.tiers;

        this.guestlist_submissions.count = data.guestlists.guestlist_submissions
          .map(t => t.contacts)
          .reduce((a, b) => a + b, 0);
        this.guestlist_sections.count = data.guestlists.guestlist_sections
          .map(t => t.contacts)
          .reduce((a, b) => a + b, 0);
        this.guestlist_bottles.count = data.guestlists.guestlist_bottles
          .map(t => t.contacts)
          .reduce((a, b) => a + b, 0);
        this.guestlist_foods.count = data.guestlists.guestlist_foods
          .map(t => t.contacts)
          .reduce((a, b) => a + b, 0);

        this.guestlist_packages.count = data.guestlists.guestlist_packages
          .map(t => t.contacts)
          .reduce((a, b) => a + b, 0);

        this.lists = data.lists;
        this.emailsSent = data.event.emails_sent || 0;
        this.total = data.tiers.map(t => t.count).reduce((a, b) => a + b, 0);
        this.coEvents = data.events;
        this.templates = data.previous_emails;
        this.counts = data.counts;
        this.presaleUsers.counts = data.presales;
      });

    if(this.$store.state.user.activeBrand.subdomain == 'ownyourworth') {
      this.formValues.email = 'hello@ownyourworthexperience.com';
    }
  },
  mounted() {
    document.body.style.overflow = "initial";
  }
};
</script>

<style lang="less" scoped>
.instant-email {
  .usage {
    display: flex;
    position: absolute;
    right: 0;
    top: 20px;
    padding: 10px;
    flex-direction: column;
    background-color: var(--input-background);
    border: 1px solid var(--input-border);
    border-radius: 7px;
    box-shadow: 1px 1px 7px 1px #00000061;

    .counter {
      display: flex;
      margin-bottom: 10px;
      justify-content: center;
      align-items: center;

      .count {
        font-size: 40px;
        font-weight: bold;
        color: @colors[primary-blue];
        line-height: 1;
      }

      .free-emails {
        margin-left: 20px;
        text-transform: uppercase;
        font-weight: bold;
      }
    }

    .additional {
      font-size: 14px;
      text-align: center;
    }

    @media screen and (max-width: 615px) {
      top: 80px;
      left: 0;
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-end;

    button {
      margin-right: 10px;
      width: 100px;
    }
  }

  .who-to-mail {
    width: calc(100% - 22px);

    h4,
    h5 {
      margin: 0 0 5px 0;
    }

    .select-button {
      margin: 10px auto;
    }

    .event {
      padding: 10px 10px 2px 10px;
      margin-bottom: 10px;
      border: 1px solid #333b3f;
      border-radius: 5px;
    }

    .co-events-title {
      margin-bottom: 30px;

      h2 {
        margin-bottom: 0;
      }

      p {
        margin: 0;
        color: @colors[text-secondary];
      }
    }

    .error {
      color: @colors[error-red];
      margin-bottom: 10px;
    }
  }

  .row {
    @media screen and (max-width: 1000px) {
      flex-direction: row;

      .who-to-mail {
        width: 100%;
        margin-right: 0;
      }

      .col {
        width: 100% !important;
      }

      .co-events {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 10px);

        .event {
          width: calc(50% - 32px);
          padding-right: 10px;
          margin-right: 10px;
        }
      }

      .button-wrapper {
        margin-top: 30px;
      }
    }

    @media screen and (max-width: 430px) {
      .co-events {
        & > div {
          width: 100%;
        }
      }
    }
  }

  #compose-mail-section {
    &::before {
      content: "";
      position: absolute;
      left: calc(25% - 12px);
      height: 80%;
      border: rgba(91, 91, 91, 0.5) solid 0.5px;
    }

    p {
      margin-bottom: 20px;
    }

    @media screen and (max-width: 920px) {
      margin-top: 30px;
    }

    @media screen and (max-width: 1000px) {
      &::before {
        display: none;
      }
    }

    .template-name {
      display: flex;

      button {
        margin: 32px 0 auto 16px;
      }
    }
  }

  #wysiwyg-wrapper {
    margin-bottom: 30px;
  }

  @media screen and (max-width: 615px) {
    .who-to-mail {
      margin-top: 110px;
    }
  }
}
</style>
