<template>
  <div class="automation">
    <update-automation-template-modal
      ref="updateAutomationTemplateModal"
      :event="url"
      :languages="languages"
      @submit="onAutomationChange"
    />
    <custom-automation-template-modal
      ref="customAutomationTemplateModal"
      :event="url"
      :languages="languages"
      :customTypes="customTypes"
      :customAutomationTypes="customAutomationTypes"
      @submit="onCustomAutomationChange"
      />
    <h2>Automation</h2>
    <div v-if="automations === null" class="automations">
      <loader/>
    </div>
    <div v-else class="automations">
      <automation-tile
        v-for="(automation, index) in automations"
        :value="automation"
        :index="index"
        :key="automation.type"
        @change="onAutomationChange"
        @update-template="onUpdateTemplate"
      />
    </div>

    <h2>Custom Automation</h2>
    <div class="center">
      <diyobo-button type="primary" txt="Add custom automation" @click="addCustomAutomation"/>
    </div>
    <div class="automations">
      <custom-automation-tile
        v-for="(automation, index) in customAutomations"
        :value="automation"
        :index="index"
        :key="index"
        @change="onCustomAutomationChange"
        @update-template="onCustomUpdateTemplate"
      />
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import AutomationTile from "../../components/emails/AutomationTile.vue";
import CustomAutomationTile from "../../components/emails/CustomAutomationTile.vue";
import UpdateAutomationTemplateModal from "../../components/modals/UpdateAutomationTemplateModal.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import CustomAutomationTemplateModal from "../../components/modals/CustomAutomationTemplateModal.vue";

export default {
  name: "email-automation",
  components: {
    CustomAutomationTemplateModal,
    DiyoboButton,
    Loader,
    AutomationTile,
    CustomAutomationTile,
    UpdateAutomationTemplateModal
  },
  props: {
    url: String
  },
  head() {
    return {
      title: "Automation"
    };
  },
  data() {
    return {
      automations: null,
      customAutomations: null,
      languages: [],
      eventId: null,
      customTypes: [],
      customAutomationTypes: [],
    };
  },
  methods: {
    onUpdateTemplate(automation, index) {
      this.$refs.updateAutomationTemplateModal.open({automation, index});
    },
    onCustomUpdateTemplate(automation, index) {
      this.$refs.customAutomationTemplateModal.open({automation, index});
    },
    onAutomationChange(config, index) {
      const automation = this.automations[index];
      // if this is coming from the template editor, then we want to instantly update the template type
      if (config.useCustomTemplate !== undefined) {
        automation.config = config;
      }

      this.$axios
        .post(
          `/emails/automations/${this.url}/${automation.type}/configure`,
          config
        )
        .then(response => {
          const data = response.data;

          this.$set(automation, "config", data.config);
        });
    },
    onCustomAutomationChange(config, index) {
      this.$axios
        .post(
          `/emails/automations/${this.url}/${config.type}/custom-configure`,
          config
        )
        .then(response => {
          const data = response.data;
          if(index === -1) {
            this.customAutomations.push(data.config);
          } else {
            this.customAutomations[index] = data.config;
          }
        });
    },
    addCustomAutomation() {
      this.$refs.customAutomationTemplateModal.open();
    }
  },
  created() {
    this.$axios.get("/emails/automations/" + this.url).then(response => {
      const data = response.data;

      this.$emit("set-title", data.event);

      for (const automation of data.automations) {
        automation.variables = automation.fields.reduce((acc, field) => {
          acc[field.name] = `${field.label}`;
          return acc;
        }, {});
        if (!automation.config) {
          automation.config = {
            enabled: false,
            use_custom_template: false
          };
        }
      }

      this.automations = data.automations;
      this.languages = data.event.languages || [];
      this.eventId = data.event._id;
      this.customTypes = data.customTypes;
      this.customAutomationTypes = data.customTypes.map((item) => ({
        label: item.type,
        value: item.type
      }))
      this.customAutomations = data.customAutomations;
    });
  }
};
</script>

<style lang="less" scoped>
.center {
  display: flex;
  justify-content: center;
}
</style>
