<template>
  <modal
    title="Update Automation Template"
    width="800px"
    :active="active"
    @close="close"
  >
    <h4 class="name">{{ automation.name }}</h4>
    <diyobo-checkbox
      type="check"
      label="Use Custom Template"
      v-model="formValues.useCustomTemplate"
      :checked="formValues.useCustomTemplate"
    />

    <template v-if="formValues.useCustomTemplate">
      <h4>Custom Template Content</h4>


      <template v-if="languages.length == 0">
        <FormulateInput
          type="text"
          label="Subject"
          placeholder="Subject"
          v-model="formValues.subject"
          :val="formValues.subject"
          validation="required"
        />

        <div id="wysiwyg-wrapper">
          <FormulateInput
            type="wysiwyg"
            label="Email Content"
            placeholder="Write your message here..."
            validation="required"
            :hasVars="true"
            :variables="automation.variables"
            v-model="formValues.content"
            :val="formValues.content"
          />
        </div>
      </template>
      <template v-else>
        <div
          class="translate-box"
          v-for="(translate, index) in translates"
          :key="index"
          :id="`translate-${index}`"
        >
          <FormulateInput
            type="selectSearch"
            label="Language"
            placeholder="Select Language"
            validation="required"
            :options="filterNonselectedTranslateLanguages"
            v-model="translate.language"
            :disabled="translate.language === 'English'"
          />
          <FormulateInput
            type="text"
            label="Subject"
            placeholder="Subject"
            validation="required"
            v-model="translate.subject"
          />

          <div id="wysiwyg-wrapper">
            <FormulateInput
              type="wysiwyg"
              label="Email Content"
              placeholder="Write your message here..."
              validation="required"
              :hasVars="true"
              :variables="automation.variables"
              v-model="translate.content"
            />
          </div>
          <FormulateInput
            type="button"
            name="Delete"
            v-if="index > 0"
            @click="removeTranslate(index)"
          />
        </div>
        <FormulateInput
          type="button"
          name="Add Another Language"
          @click="addTranslate"
        />
      </template>
      <!--      <diyobo-input-->
      <!--        type="dropdown"-->
      <!--        label="Previous Email"-->
      <!--        placeholder="Select a Previous Email"-->
      <!--        sublabel="You can select one of your previous emails from this dropdown to populate its content below."-->
      <!--        :options="previousEmails"-->
      <!--        :val="previousEmail"-->
      <!--        :bus="bus"-->
      <!--        @input="onSelectPreviousEmail"-->
      <!--      />-->
      <!--      <div class="input-wrapper col full template-name">-->
      <!--        <diyobo-input-->
      <!--          type="text"-->
      <!--          label="Template Name"-->
      <!--          placeholder="My Template"-->
      <!--          sublabel="Save the content below as a template for use in future emails."-->
      <!--          v-model="formValues.templateName"-->
      <!--          :val="formValues.templateName"-->
      <!--          :error="formErrors.templateName"-->
      <!--        />-->
      <!--        <diyobo-button type="primary" txt="Save" @click="saveTemplate" />-->
      <!--      </div>-->
      <!--      <diyobo-input-->
      <!--        type="text"-->
      <!--        label="Name"-->
      <!--        placeholder="Name"-->
      <!--        required-->
      <!--        :val="formValues.name"-->
      <!--        :error="formErrors.name"-->
      <!--        v-model="formValues.name"-->
      <!--        @keyup="validateAt('name')"-->
      <!--      />-->
      <!--      <diyobo-input-->
      <!--        type="text"-->
      <!--        label="Email"-->
      <!--        placeholder="Email"-->
      <!--        required-->
      <!--        :val="formValues.email"-->
      <!--        :error="formErrors.email"-->
      <!--        v-model="formValues.email"-->
      <!--        @keyup="validateAt('email')"-->
      <!--      />-->

      <!-- <div id="wysiwyg-wrapper">
        <FormulateInput
          type="wysiwyg"
          label="Email Content"
          placeholder="Write your message here..."
          validation="required"
          v-model="formValues.content"
          :val="formValues.content"
          :error="formErrors.content"
          @keyup="validateAt('content')"
          :hasVars="true"
          :variables="automation.variables"
        />
      </div> -->
    </template>

    <div v-else class="default-template">
      <h4>Default Template Content</h4>
      <div class="row field">
        <span>Subject</span>
        <div v-html="automation.subject"></div>
      </div>
      <div class="row field">
        <span>Content</span>
        <div v-html="automation.content"></div>
      </div>
    </div>

    <template #footer>
      <diyobo-button type="secondary" txt="Close" @click="close"/>
      <diyobo-button type="primary" txt="Save" @click="submit"/>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import DiyoboCheckbox from "@/components/DiyoboCheckbox.vue";
import wysiwyg from "../../components/WYSIWYG.vue";
import * as yup from "yup";
import Vue from "vue";

const VALIDATION_SCHEMA = yup.object({
  subject: yup
    .string()
    .nullable()
    .required("Subject is required."),
  content: yup
    .string()
    .nullable()
    .required("Content is required.")
});

const TEMPLATE_VALIDATION_SCHEMA = yup.object({
  subject: yup.string().required("Subject Line is required."),
  content: yup.string().required("Content is required.")
});

export default {
  name: "update-automation-template-modal",
  components: {
    Modal,
    DiyoboInput,
    DiyoboButton,
    DiyoboCheckbox,
    wysiwyg
  },
  props: {
    event: String,
    email: Object,
    languages: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      active: false,
      automation: {subject: "", content: ""},
      index: -1,
      bus: new Vue(),
      previousEmail: null,
      formValues: {
        useCustomTemplate: false,
        subject: "",
        content: ""
      },
      formErrors: {
        useCustomTemplate: false,
        subject: "",
        content: ""
      },
      translates: [
        {
          language: "English",
          subject: "",
          content: ""
        }
      ]
    };
  },
  computed: {
    previousEmails() {
      if (!this.automation || !this.automation.previous_templates) {
        return [];
      }

      return this.automation.previous_templates.map(t => ({
        label: t.template_name,
        value: t.id
      }));
    },
    filterNonselectedTranslateLanguages() {
      return this.languages.filter(
        l => !this.translates.find(t => t.language === l)
      );
    }
  },
  methods: {
    addTranslate() {
      if (this.filterNonselectedTranslateLanguages.length === 0) {
        return;
      }
      this.translates.push({
        language: "",
        subject: "",
        content: ""
      });

      const index = this.translates.length - 1;

      setTimeout(() => {
        document.getElementById(`translate-${index}`).scrollIntoView({
          behavior: "smooth"
        });
      }, 100);
    },
    removeTranslate(index) {
      if (this.translates[index].content) {
        return;
      }
      setTimeout(() => {
        this.translates.splice(index, 1);
      }, 100);
    },

    validateAt(field) {
      VALIDATION_SCHEMA.validateAt(field, this.formValues)
        .then(() => {
          this.formErrors[field] = "";
        })
        .catch(err => {
          this.formErrors[field] = err.message;
        });
    },
    // onSelectPreviousEmail(value) {
    //   const template = this.automation.previous_templates.find(
    //     e => e.id === value
    //   );
    //
    //   this.previousEmail = value;
    //
    //   this.formValues.templateName = template.template_name;
    //   this.formValues.name = template.name;
    //   this.formValues.email = template.email;
    //   this.formValues.subject = template.subject;
    //   this.formValues.content = template.content;
    //
    //   this.validateAt("name");
    //   this.validateAt("email");
    //   this.validateAt("subject");
    //   this.validateAt("content");
    // },
    open(args = {}) {
      this.automation = args.automation;
      this.index = args.index;
      const config = args.automation.config;
      this.formValues.subject = this.automation.config.subject || "";
      this.formValues.content = this.automation.config.content || "";
      if (config) {
        this.formValues.useCustomTemplate = config.use_custom_template;
        if (config.translations) {
          if (config.translations.length > 0) {
            this.translates = config.translations;
          }
        }
      }

      document.body.style.overflow = "hidden";

      setTimeout(() => {
        this.active = true;
      }, 250);
    },
    close() {
      this.active = false;

      setTimeout(() => {
        document.body.style.position = "relative";

        this.automation = {subject: "", content: ""};
        this.translates = [
          {
            language: "English",
            subject: "",
            content: ""
          }
        ];
        this.index = -1;
      }, 500);
    },
    async saveTemplate() {
      // lazybones detected
      this.formErrors.templateName = "";

      try {
        await TEMPLATE_VALIDATION_SCHEMA.validate(this.formValues, {
          abortEarly: false
        });
      } catch (error) {
        error.inner.forEach(err => {
          this.formErrors[err.path] = err.message;
        });

        return false;
      }

      this.$loader.start();

      this.$axios
        .post("/emails/save-template", {
          event: this.event,
          templateName: this.formValues.templateName,
          type: "AUTOMATION",
          automationType: this.automation.type,
          name: this.formValues.name,
          email: this.formValues.email,
          subject: this.formValues.subject,
          content: this.formValues.content
        })
        .then(response => {
          const data = response.data;
          const template = data.template;
          const index = this.automation.previous_templates.findIndex(
            t => t.id === template.id
          );

          if (index > -1) {
            this.automation.previous_templates.splice(index, 1, template);
          } else {
            this.automation.previous_templates.unshift(template);
          }

          this.$bus.$emit("alert", {
            msg: "Your email template has been saved!"
          });
        })
        .finally(() => {
          this.$loader.stop();
        });
    },
    async submit() {
      if (this.languages.length == 0) {
        try {
          await VALIDATION_SCHEMA.validate(this.formValues, {
            abortEarly: false
          });
        } catch (error) {
          error.inner.forEach(err => {
            this.formErrors[err.path] = err.message;
          });
        }

        // // if we aren't using the custom template we don't really care if it's filled out
        // if (this.formValues.useCustomTemplate) {
        //   return;
        // }
      }

      const enabled = this.automation.config
        ? this.automation.config.enabled
        : false;

      let config = {
        enabled,
        ...this.formValues,
      };
      if (this.languages.length) {
        config.translates = this.translates;
      }
      console.log('here');
      this.$emit("submit", config, this.index);
      this.close();
    }
  }
};
</script>

<style lang="less" scoped>
.modal &::v-deep {
  .name {
    margin: 0 0 20px 0;
    text-align: center;
  }

  .default-template {
    .row {
      margin-bottom: 8px !important;
    }

    .field {
      display: flex;
      flex-direction: column;

      span {
        font-weight: bold;
      }
    }
  }

  .template-name {
    display: flex;

    button {
      margin: 32px 0 auto 16px;
    }
  }

  .modal-footer {
    button {
      margin-left: 16px;
    }
  }
}
</style>
