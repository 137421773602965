<template>
  <modal
    title="Custom Automation Template"
    width="800px"
    :active="active"
    @close="close"
  >
    <h4>Custom Template Content</h4>
    <FormulateForm
      ref="form"
      keep-model-data
      :debounce="100"
      v-model="formValues"
      autocomplete="false"
      @submit="submit"
    >
      <FormulateInput
        type="selectSearch"
        label="Type"
        placeholder="Select Type"
        validation="required"
        :options="customAutomationTypes"
        v-model="formValues.type"
        @input="setFields"
      />
      <div v-if="formValues.type">
        <FormulateInput
          type="dateTime"
          v-model="formValues.date"
          label="Date"
          :min-date="new Date()"
          placeholder="MM/DD/YYYY ##:## PM"
          validation="required"
          autocomplete="false"
        />
        <FormulateInput
          type="checkbox"
          v-model="formValues.with_out_headers"
          label="Use template with out header and footer"
          autocomplete="off"
        />
        <div v-if="languages.length == 0">
          <FormulateInput
            type="text"
            label="Subject"
            placeholder="Subject"
            v-model="formValues.subject"
            :val="formValues.subject"
            validation="required"
          />

          <div id="wysiwyg-wrapper">
            <FormulateInput
              type="wysiwyg"
              label="Email Content"
              placeholder="Write your message here..."
              validation="required"
              :hasVars="true"
              :variables="fields"
              v-model="formValues.content"
              :val="formValues.content"
            />
          </div>
        </div>
        <div v-else>
          <div
            class="translate-box"
            v-for="(translate, index) in translates"
            :key="index"
            :id="`translate-${index}`"
          >
            <FormulateInput
              type="selectSearch"
              label="Language"
              placeholder="Select Language"
              validation="required"
              :options="filterNonselectedTranslateLanguages"
              v-model="translate.language"
              :disabled="translate.language === 'English'"
            />
            <FormulateInput
              type="text"
              label="Subject"
              placeholder="Subject"
              validation="required"
              v-model="translate.subject"
            />

            <div id="wysiwyg-wrapper">
              <FormulateInput
                type="wysiwyg"
                label="Email Content"
                placeholder="Write your message here..."
                validation="required"
                :hasVars="true"
                :variables="fields"
                v-model="translate.content"
              />
            </div>
            <FormulateInput
              type="button"
              name="Delete"
              v-if="index > 0"
              @click="removeTranslate(index)"
            />
          </div>
          <FormulateInput
            type="button"
            name="Add Another Language"
            @click="addTranslate"
          />
        </div>
      </div>
      <div class="row">
        <div class="col half">
          <FormulateInput
            class="cancel-button"
            type="button"
            @click="close"
            name="Close"
          />
        </div>
        <div class="col half">
          <FormulateInput
            type="submit"
            :disabled="isLoading"
            :name="isLoading ? 'Saving...' : 'Save'"
          />
        </div>
      </div>

    </FormulateForm>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import DiyoboCheckbox from "@/components/DiyoboCheckbox.vue";
import wysiwyg from "../../components/WYSIWYG.vue";
import * as yup from "yup";
import Vue from "vue";
import dayjs from "dayjs";

const VALIDATION_SCHEMA = yup.object({
  subject: yup
    .string()
    .nullable()
    .required("Subject is required."),
  type: yup
    .string()
    .nullable()
    .required("Type is required."),
  date: yup
    .date()
    .nullable()
    .required("Date is required."),
  content: yup
    .string()
    .nullable()
    .required("Content is required.")
});

export default {
  name: "custom-automation-template-modal",
  components: {
    Modal,
    DiyoboInput,
    DiyoboButton,
    DiyoboCheckbox,
    wysiwyg
  },
  props: {
    event: String,
    email: Object,
    languages: {
      type: Array,
      default: () => []
    },
    customTypes: {
      type: Array,
      default: () => []
    },
    customAutomationTypes: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      active: false,
      automation: {subject: "", content: ""},
      index: -1,
      bus: new Vue(),
      previousEmail: null,
      isLoading: false,
      formValues: {
        type: null,
        subject: "",
        content: "",
        date: null,
        with_out_headers: false,
      },
      formErrors: {
        subject: "",
        content: ""
      },
      translates: [
        {
          language: "English",
          subject: "",
          content: ""
        }
      ],
      fields: []
    };
  },
  computed: {
    filterNonselectedTranslateLanguages() {
      return this.languages.filter(
        l => !this.translates.find(t => t.language === l)
      );
    }
  },
  methods: {
    addTranslate() {
      if (this.filterNonselectedTranslateLanguages.length === 0) {
        return;
      }
      this.translates.push({
        language: "",
        subject: "",
        content: ""
      });

      const index = this.translates.length - 1;

      setTimeout(() => {
        document.getElementById(`translate-${index}`).scrollIntoView({
          behavior: "smooth"
        });
      }, 100);
    },
    removeTranslate(index) {
      if (this.translates[index].content) {
        return;
      }
      setTimeout(() => {
        this.translates.splice(index, 1);
      }, 100);
    },

    validateAt(field) {
      VALIDATION_SCHEMA.validateAt(field, this.formValues)
        .then(() => {
          this.formErrors[field] = "";
        })
        .catch(err => {
          this.formErrors[field] = err.message;
        });
    },
    open(args = {}) {
      if (args.automation) {

        this.automation = args.automation;
        this.index = args.index;
        this.formValues.type = this.automation.automation_type.type;
        this.formValues.subject = this.automation.subject || "";
        this.formValues.content = this.automation.content || "";
        this.formValues.with_out_headers = this.automation.with_out_headers;
        this.formValues.date = dayjs(+this.automation.date.$date.$numberLong)
          .format("ddd, MMM DD, YYYY, hh:mm A ");
        this.setFields();
        if (this.automation.translations && this.automation.translations.length > 0) {
            this.translates = this.automation.translations;
        }
        this.$forceUpdate();
      }
      document.body.style.overflow = "hidden";

      setTimeout(() => {
        this.active = true;
      }, 250);
    },
    close() {
      this.active = false;

      setTimeout(() => {
        document.body.style.position = "relative";
        this.formValues = {
          type: null,
          subject: "",
          content: "",
          date: null,
          with_out_headers: false
        };
        this.automation = {subject: "", content: "", type: ""};
        this.translates = [
          {
            language: "English",
            subject: "",
            content: ""
          }
        ];
        this.fields = [];
        this.index = -1;
      }, 500);
    },
    async submit() {
      if (this.languages.length == 0) {
        try {
          await VALIDATION_SCHEMA.validate(this.formValues, {
            abortEarly: false
          });
        } catch (error) {
          error.inner.forEach(err => {
            this.formErrors[err.path] = err.message;
          });
        }
      }

      const enabled = this.automation.enabled || false;

      let config = {
        enabled,
        ...this.formValues,
      };
      if(this.automation._id){
        config._id = this.automation._id;
      }
      if (this.languages.length) {
        config.translates = this.translates;
      }
      this.$emit("submit", config, this.index);
      this.close();
    },
    setFields() {
      if(this.formValues.type) {
        this.customTypes.forEach(item => {
          if(item.type === this.formValues.type){
            this.fields = item.fields.reduce((acc, field) => {
              acc[field.name] = `${field.label}`;
              return acc;
            }, {});
          }
        })
      }
    }
  }
};
</script>

<style lang="less" scoped>
.modal &::v-deep {
  .name {
    margin: 0 0 20px 0;
    text-align: center;
  }

  .default-template {
    .row {
      margin-bottom: 8px !important;
    }

    .field {
      display: flex;
      flex-direction: column;

      span {
        font-weight: bold;
      }
    }
  }

  .template-name {
    display: flex;

    button {
      margin: 32px 0 auto 16px;
    }
  }

  .modal-footer {
    button {
      margin-left: 16px;
    }
  }
}
.cancel-button {
  text-align: -webkit-right;
  &::v-deep button {
    background: var(--primary-blue);
  }
}
</style>
