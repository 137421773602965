<template>
  <div class="automation-tile" :class="{ inactive: value.inactive }">
    <div class="event-inner">
      <div class="left">
        <div class="event-details">
          <div class="event-name">{{ value.automation_type.name }} - {{date}}</div>
          <div class="more-info">
            <div class="event-date">
              {{ completed ? "Completed" : enabled ? "Enabled" : "Disabled" }}
            </div>
            <div class="stats">
              <div v-if="sent === 1" class="creation-date">
                Sent {{ sent }} time
              </div>

              <div v-else class="creation-date">Sent {{ sent }} times</div>

              <div v-if="opens === 1" class="creation-date">
                Opened {{ opens }} time
              </div>
              <div v-else class="creation-date">Opened {{ opens }} times</div>
              <div v-if="delivered === 1" class="creation-date">
                Delivered {{ delivered }} time
              </div>
              <div v-else class="creation-date">Delivered {{ delivered }} times</div>
            </div>
            <div class="unique-stats">
              <div v-if="uniqueOpens === 1" class="creation-date">
                Unique opened {{ uniqueOpens }} time
              </div>
              <div v-else class="creation-date">Unique opened {{ uniqueOpens }} times</div>
              <div v-if="uniqueClicks === 1" class="creation-date">
                Unique clicked {{ uniqueClicks }} time
              </div>
              <div v-else class="creation-date">Unique clicked {{ uniqueClicks }} times</div>
            </div>
          </div>
        </div>
      </div>
      <!-- end left -->
      <div class="right">
        <div class="tix-sold" @click="$emit('update-template', value, index)">
          <span>Selected Template</span>
          <div class="tix-sold-number">Custom</div>
          <div class="tix-sold-label">Click to Update</div>
        </div>
        <button
          type="button"
          :class="{ disable: !enabled }"
          @click="onToggleActive"
        >
          {{ enabled ? "Enabled" : "Disabled" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "debounce";
import dayjs from "dayjs";

export default {
  name: "CustomAutomationTile",
  props: {
    value: Object,
    index: Number
  },
  computed: {
    enabled: {
      get() {
        return this.value && this.value.enabled;
      },
      set(value) {
        this.value.enabled = value;
      }
    },
    completed() {
      return this.value && this.value.completed;
    },
    sent() {
      return this.value ? this.value.sent || 0 : 0;
    },
    opens() {
      return this.value ? this.value.opens || 0 : 0;
    },
    delivered() {
      return this.value ? this.value.delivered || 0 : 0;
    },
    uniqueOpens() {
      return this.value ? this.value.unique_opens || 0 : 0;
    },
    uniqueClicks() {
      return this.value ? this.value.unique_clicks || 0 : 0;
    },
    date() {
      return dayjs(+this.value.date.$date.$numberLong)
        .format("ddd, MMM DD, YYYY, hh:mm A ")
    }
  },
  methods: {
    toggleActive: debounce(function(enabled) {
      this.$emit(
        "change",
        {
          enabled,
          type: this.value.automation_type.type,
          _id: this.value._id,
        },
        this.index
      );
    }, 200),
    onToggleActive() {
      if (this.value.inactive) {
        return;
      }

      this.enabled = !this.enabled;
      this.toggleActive(this.enabled);
    }
  }
};
</script>

<style lang="less" scoped>
.automation-tile {
  margin: 0.75em auto;

  // border: 1px solid #333b3f;

  &.inactive {
    opacity: 50%;
    pointer-events: none;
  }

  .event-inner {
    border-radius: 8px;
    box-shadow: 1px 1px 2px -1px #ffffff36, inset 0px 1px 5px 0px #00000069;
    // background-image: linear-gradient(#1f1f1f, #161616);
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 52px;

    .left {
      // width: calc(100% - 400px);
      height: inherit;
      margin: 20px 0;

      .event-details {
        padding: 0 20px;
        position: relative;
        // width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .event-name {
          color: var(--text);
          font-size: 1.75em;
          font-weight: 700;
          word-break: break-word;
          line-height: 1em;
        }

        .event-date {
          margin: 10px 0;
          font-weight: 600;
          text-transform: uppercase;
          font-size: 1.05em;
          line-height: 1em;
        }

        .stats {
          display: flex;

          .creation-date {
            position: relative;
            margin-right: 24px;
            color: var(--headers);
            font-size: 1.05em;
            font-weight: 600;
            text-transform: uppercase;
            line-height: 1em;
          }
        }
        .unique-stats {
          display: flex;
          margin-top: 10px;

          .creation-date {
            position: relative;
            margin-right: 24px;
            color: var(--headers);
            font-size: 1.05em;
            font-weight: 600;
            text-transform: uppercase;
            line-height: 1em;
          }
        }
      }
    }

    // end left
    .right {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      width: 380px;
      margin-left: 20px;

      .tix-sold {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 250px;
        padding: 5px 1px;
        margin: 10px 0;
        border: 1px solid var(--dashboard-border);
        border-radius: 10px;
        background-image: var(--dashboard-tiles);
        box-shadow: var(--dashboard-shadow);
        cursor: pointer;
        user-select: none;

        .tix-sold-number {
          font-size: 30px;
          font-weight: 700;
          color: var(--primary-green);
        }

        .tix-sold-label {
          padding: 5px 8px;
          // width: 75px;
          font-size: 15px;
          font-weight: 700;
          line-height: 100%;
          text-transform: uppercase;
        }
      }

      button {
        margin: 10px;
        padding: 3px;
        width: 105px;
        min-height: 50px;
        font-family: inherit;
        font-size: 1.25em;
        font-weight: 700;
        line-height: 1em;
        text-transform: uppercase;
        color: #ffffff;
        border: none;
        border-radius: 10px;
        background: var(--secondary-green);
        -webkit-appearance: none;
        outline: none;
        cursor: pointer;

        &.disable {
          background: var(--gray-6);
        }
      }
    }
    // end right
  }

  @media screen and (max-width: 780px) {
    .event-inner {
      max-width: min-content;

      .right {
        .tix-sold {
          width: 261px;
        }
        button {
          margin-right: 0;
        }

        margin-right: 20px;
        margin-bottom: 10px;
      }
    }
  }
}

@media screen and (max-width: 456px) {
  .automation-tile {
    .event-inner {
      .right {
        .tix-sold {
          width: 100%;
        }
        button {
          margin-left: 0;
          width: 100%;
        }
      }
      .event-details {
        .event-name {
          font-size: 1em;
        }
        .event-date {
          font-size: 0.9em;
        }
        .creation-date {
          font-size: 0.7em;
        }
      }
    }
  }
}
</style>
