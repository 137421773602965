<template>
  <div class="import-lists">
    <h2>Import Lists</h2>
    <import-contacts :lists="lists" :event="url" @submit="onSubmitImport" />
    <div class="divider"></div>
    <div class="your-lists">
      <h3>Your Lists</h3>
      <div v-if="lists === null" class="lists">
        <loader />
      </div>
      <div v-else-if="lists.length === 0" class="lists">
        You have no imported lists.
      </div>
      <div v-else class="lists">
        <section
          class="table-one"
          v-for="(data, index) in lists"
          :id="`table-` + index"
          :key="index"
        >
          <div class="row">
            <div class="col full">
              <div class="btn" :class="{ active: data.show }">
                <div class="button-section">
                  <button class="header-button" @click="openOrClose(data)">
                    <div class="left">
                      <span>{{ data.name }}</span>
                    </div>
                    <div class="right">
                      <span>
                        <font-awesome-icon icon="id-card" class="info-icon" />
                        {{ data.contacts }}
                        <span v-if="data.contacts === 1">
                          Contact
                        </span>
                        <span v-else>
                          Contacts
                        </span>
                      </span>
                    </div>
                  </button>
                  <font-awesome-icon
                    v-if="data.show"
                    icon="chevron-up"
                    class="toggle-icon"
                  />
                  <font-awesome-icon
                    v-else
                    icon="chevron-down"
                    class="toggle-icon"
                  />
                </div>
                <div class="table-section" v-if="data.show">
                  <loader v-if="!data.loaded" />
                  <table-tote v-else v-model="data.contactsTable" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import DiyoboButton from "@/components/DiyoboButton.vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import Loader from "@/components/Loader.vue";
import Vue from "vue";
import ImportContacts from "../../components/emails/ImportContacts.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronDown,
  faChevronUp,
  faIdCard
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import TableTote from "@/components/TableTote.vue";

library.add(faChevronUp, faChevronDown, faIdCard);

export default {
  name: "email-import-lists",
  components: {
    DiyoboButton,
    DiyoboInput,
    Loader,
    ImportContacts,
    FontAwesomeIcon,
    TableTote
  },
  props: {
    url: String
  },
  head() {
    return {
      title: "Import Lists"
    };
  },
  data() {
    return {
      contacts: [],
      lists: null,
      selectedList: null,
      listName: "",
      bus: new Vue()
    };
  },
  methods: {
    onSubmitImport(list) {
      const index = this.lists.findIndex(l => l.id === list.id);

      this.addTableDataToList(list);

      if (index > -1) {
        this.lists.splice(index, 1, list);
      } else {
        this.lists.unshift(list);
      }
    },
    openOrClose(e) {
      this.$set(e, "show", !e.show);

      if (!e.loaded && !e.fetched) {
        this.$axios.get(`/emails/lists/${e._id}/contacts`).then(response => {
          const data = response.data;

          e.contactsTable.data = data.contacts.map(c => {
            c.delete = true;
            return c;
          });

          setTimeout(() => {
            e.loaded = true;
            this.$forceUpdate();
          }, 200);
        });

        e.fetched = true;
      }
    },
    updateContact(contact, field) {
      if (contact[field] === "" && field !== "phone") {
        return;
      }

      this.$axios.post("/emails/contacts/update", {
        contact: {
          id: contact._id,
          field,
          value: contact[field]
        }
      });
    },
    deleteContact(list, contact) {
      this.$bus.$emit("alert", {
        msg: "Are you sure you want to remove this contact from the list?",
        closable: true,
        onConfirm: () => {
          this.$axios.post("/emails/lists/remove-contact", {
            id: contact._id,
            listID: list._id
          });

          const index = list.contactsTable.data.indexOf(contact);

          if (index > -1) {
            list.contacts--;
            list.contactsTable.data.splice(index, 1);
          }
        }
      });
    },
    addTableDataToList(list) {
      list.contactsTable = {
        title: "Contacts",
        columns: [
          {
            title: "First Name",
            field: "first_name",
            width: "25%",
            input: true
          },
          {
            title: "Last Name",
            field: "last_name",
            width: "25%",
            input: true
          },
          { title: "Email", field: "email", width: "25%", input: true },
          {
            title: "Phone Number",
            field: "phone",
            width: "25%",
            input: true,
            phone: true
          },
          { title: "Delete", field: "delete", width: "7%", icon: true }
        ],
        data: [],
        searchable: ["first_name", "last_name", "email"],
        icons: {
          delete: () => "trash"
        },
        callbacks: {
          first_name: this.updateContact,
          last_name: this.updateContact,
          email: this.updateContact,
          phone: this.updateContact,
          delete: contact => this.deleteContact(list, contact)
        }
      };
    }
  },
  created() {
    this.$axios.get("/emails/lists/" + this.url).then(response => {
      const data = response.data;

      this.$emit("set-title", data.event);

      this.lists = data.lists.map(l => {
        this.addTableDataToList(l);
        return l;
      });
    });
  }
};
</script>

<style lang="less" scoped>
.btn {
  width: calc(100% - 4px);
  padding: 1px;
  border-radius: 5px;
  background-image: linear-gradient(160deg, #4eb95e, #4a639e);
  transition: 0.4s;

  .header-button {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    font-size: 1.25em;
    width: 100%;
    border: none;
    background: var(--input-background);
    border-radius: 4px;
    color: var(--text);
    outline: none;
    text-align: left;
    cursor: pointer;
    flex-wrap: wrap;
  }

  &.active {
    .header-button {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .button-section {
      border-bottom-right-radius: 0;
    }

    .table-section {
      max-height: 10000px;
    }
  }

  .button-section {
    display: flex;
    position: relative;
    background-color: #181a1f;
    border-radius: 4px;

    .left {
      font-weight: bold;
    }

    .right {
      display: flex;
      flex-wrap: wrap;
      font-size: 17px;
    }

    span {
      padding-right: 25px;
      white-space: nowrap;

      &.tickets {
        padding-right: 0;
      }
    }

    .toggle-icon {
      position: absolute;
      top: 18px;
      right: 20px;
    }
  }

  .table-section {
    max-height: 0;
    background-image: var(--dashboard-tiles);
    border-top: 1px solid #666666;
    overflow: hidden;
    transition: 0.4s;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .button-wrapper {
      padding: 20px;
    }

    .table {
      padding: 0 20px;
    }
  }
}
</style>
