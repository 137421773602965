<template>
  <modal
    class="select-co-events-modal"
    title="Select Co-event Tiers"
    width="640px"
    :active="active"
    @close="close"
  >
    <!--    TODO select all-->
    <div class="top">
      <div class="overview">
        <span>Co-events Selected: {{ coEventsSelected }}</span>
        <span>Tiers Selected: {{ coEventTiersSelected }}</span>
        <span>Potential Recipients: {{ coEventRecipients }}</span>
      </div>
      <diyobo-button
        class="select-button"
        type="primary"
        :txt="allTiersSelected ? 'Deselect All' : 'Select All'"
        @click="onSelectAllEvents"
      />
    </div>

    <div class="search">
      <FormulateInput
        type="text"
        label="Search Event or Location"
        v-model="searchString"
      />
    </div>

    <div v-for="event in filteredEvents" class="event" :key="event.id">
      <h5>
        {{ event.name }} | {{ event.city }}, {{ event.region_name }} | Date:
        {{ starts(event) }}
        <!-- {{ starts(event) }} -->
      </h5>
      <TierSelection
        v-for="tier in event.tiers"
        :tier="tier"
        :event="event.id"
        :selections="selections"
        :key="tier.id"
        @selected="handleSelected"
      />
    </div>

    <template v-slot:footer>
      <diyobo-button type="secondary" txt="Save" @click="close" />
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import DiyoboCheckbox from "@/components/DiyoboCheckbox.vue";
import TierSelection from "../emails/TierSelection";
import dayjs from "dayjs";
import { trim } from "lodash";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

export default {
  name: "select-co-events-modal",
  components: {
    TierSelection,
    Modal,
    DiyoboButton,
    DiyoboCheckbox
  },
  props: {
    value: Array,
    selections: Object,
    coEventsSelected: Number,
    coEventTiersSelected: Number,
    coEventRecipients: Number
  },
  data() {
    return {
      active: false,
      searchString: ""
    };
  },
  computed: {
    allTiersSelected() {
      // return
      let allSelectd = false;
      Object.entries(this.value).forEach(([k, s]) => {
        allSelectd = s.tiers.every(t => t.selected);
      });
      return allSelectd;
    },
    filteredEvents() {
      if (trim(this.searchString)) {
        let filteredArray = this.value.filter(
          item =>
            item.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
            item.city.toLowerCase().includes(this.searchString.toLowerCase())
        );
        return filteredArray;
      } else {
        return this.value;
      }
    }
  },
  methods: {
    open(args = {}) {
      this.onConfirm = args.onConfirm;
      // this.$refs.modal.style.display = 'block'
      document.body.style.overflow = "hidden";

      setTimeout(() => {
        this.active = true;
      }, 250);
    },
    close() {
      this.active = false;
      setTimeout(() => {
        // this.$refs.modal.removeAttribute('style')
        document.body.style.position = "relative";
      }, 500);
    },
    okay() {
      if (this.onConfirm) this.onConfirm();
      this.close();
    },
    starts(event) {
      // if (
      //   event.hasOwnProperty("frequency") &&
      //   event.frequency !== "SINGLE_DATE"
      // ) {
      //   if (event.dates.length) {
      //     let date = event.dates[0].start.hasOwnProperty("$date")
      //       ? +event.dates[0].start.$date.$numberLong
      //       : event.dates[0].start;

      //     return dayjs(date).tz(event.timezone);
      //   } else {
      //     return dayjs(+event.end.$date.$numberLong).tz(event.timezone);
      //   }
      // }
      // return "Dhruv";

      return dayjs(+event.start.$date.$numberLong)
        .tz(event.timezone)
        .format("ddd MMM DD, YYYY h:mm A z");
    },

    // dateString() {
    //   return this.starts.format("ddd, MMM DD, YYYY h:mm A z");
    // },
    handleSelected(item, value) {
      this.$emit("selected", item, value);
    },

    handleCheckbox(event, tier, value) {
      const index = this.value.indexOf(event);

      if (index > -1) {
        const tiers = this.value[index].tiers.map(t => {
          if (t.id === tier.id) {
            t.selected = value;
          }

          return t;
        });

        this.$emit("update", index, tiers);
      }
    },
    isAllTiersSelected(event) {
      return event.tiers.every(t => t.disabled || t.selected);
    },
    onSelectAllEvents() {
      const selected = !this.allTiersSelected;

      Object.entries(this.value).forEach(([k, s]) => {
        // console.log(s);
        // if (s._id === "63fbb1b04708384e830075f6") {
        //   s.tiers.forEach(t => {
        //     this.handleSelected(t, selected);
        //   });
        // }
        s.tiers.forEach(t => {
          this.handleSelected(t, selected);
        });

        // s.dates.forEach(t => {
        //   this.handleSelected("tiers", t, k, selected);
        // });

        // s.times.forEach(t => {
        //   this.handleSelected("tiers", t, k, selected);
        // });
      });

      // console.log();
    }
  }
};
</script>

<style lang="less" scoped>
.select-co-events-modal {
  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    .overview {
      display: flex;
      flex-direction: column;
    }

    .select-button {
      margin: auto 0;
    }
  }

  .events-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    .event {
      padding: 10px 10px 2px 10px;
      border: 1px solid #333b3f;
      border-radius: 5px;

      h5 {
        margin: 0 0 10px 0;
      }

      .select-button {
        margin: 0 auto 10px auto;
      }
    }

    @media screen and (max-width: 500px) {
      grid-template-columns: 1fr;
    }
  }
}
</style>
