<template>
  <modal
    class="select-co-events-modal"
    title="Select Ticket Tiers"
    width="640px"
    :active="active"
    @close="close"
  >
    <div class="top">
      <div class="headers">
        <h4>Tier</h4>
        <h4>Date</h4>
        <h4>Time</h4>
      </div>
    </div>
    <div class="events-grid">
      <TierSelection
        v-for="tier in value"
        :tier="tier"
        :event="event"
        :selections="selections"
        :key="tier.id"
        @selected="handleSelected"
      />
    </div>

    <template v-slot:footer>
      <diyobo-button type="secondary" txt="Save" @click="close" />
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import DiyoboCheckbox from "@/components/DiyoboCheckbox.vue";
import dayjs from "dayjs";
import TierSelection from "./TierSelection.vue";

export default {
  name: "SelectTiersModal",
  components: {
    TierSelection,
    Modal,
    DiyoboButton,
    DiyoboCheckbox
  },
  props: {
    value: Array,
    selections: Object,
    event: String,
    coEventsSelected: Number,
    coEventTiersSelected: Number,
    coEventRecipients: Number
  },
  data() {
    return {
      active: false
    };
  },
  computed: {
    // allTiersSelected() {
    //   return this.value
    //     ? this.value.every(e => e.tiers.every(t => t.disabled || t.selected))
    //     : false;
    // }
  },
  methods: {
    handleSelected(a, b, c, d) {
      console.log(a, b, c, d);
      this.$emit("selected", a, b, c, d);
    },
    open(args = {}) {
      this.onConfirm = args.onConfirm;
      // this.$refs.modal.style.display = 'block'
      document.body.style.overflow = "hidden";

      setTimeout(() => {
        this.active = true;
      }, 250);
    },
    close() {
      this.active = false;
      setTimeout(() => {
        // this.$refs.modal.removeAttribute('style')
        document.body.style.position = "relative";
      }, 500);
    },
    okay() {
      if (this.onConfirm) this.onConfirm();
      this.close();
    },
    handleCheckbox(event, tier, value) {
      const index = this.value.indexOf(event);

      if (index > -1) {
        const tiers = this.value[index].tiers.map(t => {
          if (t.id === tier.id) {
            t.selected = value;
          }

          return t;
        });

        this.$emit("update", index, tiers);
      }
    },
    isAllTiersSelected(event) {
      return event.tiers.every(t => t.disabled || t.selected);
    },
    onSelectAllEvents() {
      const selected = !this.allTiersSelected;

      for (let i = 0; i < this.value.length; i++) {
        this.onSelectAllEventTiers(i, selected);
      }
    },
    onSelectAllEventTiers(index, selected = undefined) {
      const event = this.value[index];

      selected =
        selected !== undefined ? selected : !this.isAllTiersSelected(event);

      const tiers = event.tiers.map(t => {
        if (!t.disabled) {
          t.selected = selected;
        }

        return t;
      });

      this.$emit("update", index, tiers);
    }
  }
};
</script>

<style lang="less" scoped>
.select-co-events-modal {
  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    .overview {
      display: flex;
      flex-direction: column;
    }

    .select-button {
      margin: auto 0;
    }

    .headers {
      display: flex;
      flex: 1;

      h4 {
        width: 33%;
        margin: 0;
      }
    }
  }

  .events-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;

    .event {
      padding: 10px 10px 2px 10px;
      border: 1px solid #333b3f;
      border-radius: 5px;

      h5 {
        margin: 0 0 10px 0;
      }

      .select-button {
        margin: 0 auto 10px auto;
      }
    }

    @media screen and (max-width: 500px) {
      grid-template-columns: 1fr;
    }
  }
}
</style>
